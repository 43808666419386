import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../main-components/Layout'
import SEO from '../components/SEO'
import { BlogPostFrontmatter, IMdx, INodes, ISite } from '../types'
import BlogPosts from '../main-components/BlogPosts'

const Index: React.FC<
  PageProps<{ site: ISite; blogPosts: INodes<IMdx<BlogPostFrontmatter>> }>
> = ({ data }) => {
  return (
    <Layout>
      <SEO />
      {/* <About /> */}
      {/* <Projects /> */}
      <BlogPosts posts={data.blogPosts.nodes} />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blogPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { show: { eq: true } } }
    ) {
      nodes {
        excerpt
        fields {
          urlpath
        }
        frontmatter {
          title
          slug
          description
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
